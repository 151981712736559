<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" width="13px" height="13px" style="&#10;    fill: #683131;&#10;">
    <g id="surface10948020">

      <path style="stroke:none;fill-rule:nonzero;fill:rgb(49.411765%,49.411765%,49.411765%);fill-opacity:1;" d="M 14.09375 1.199219 C 14.480469 1.199219 14.800781 1.519531 14.800781 1.90625 L 14.800781 14.09375 C 14.800781 14.480469 14.480469 14.800781 14.09375 14.800781 L 1.90625 14.800781 C 1.519531 14.800781 1.199219 14.480469 1.199219 14.09375 L 1.199219 1.90625 C 1.199219 1.519531 1.519531 1.199219 1.90625 1.199219 L 14.09375 1.199219 M 14.09375 0.800781 L 1.90625 0.800781 C 1.296875 0.800781 0.800781 1.296875 0.800781 1.90625 L 0.800781 14.09375 C 0.800781 14.703125 1.296875 15.199219 1.90625 15.199219 L 14.09375 15.199219 C 14.703125 15.199219 15.199219 14.703125 15.199219 14.09375 L 15.199219 1.90625 C 15.199219 1.296875 14.703125 0.800781 14.09375 0.800781 Z M 14.09375 0.800781 "/>

    </g>
  </svg>
</template>

<script>
export default {
  name: "ZTickEmptySvg"
}
</script>

<style scoped>

</style>
