<template>
  <div class="d-inline-block">
    <ZTickSvg v-if="value" />
    <ZTickEmptySvg v-else />
  </div>
</template>

<script>
import ZTickEmptySvg from "@/parts/Front/components/shared/ZTickEmptySvg";
import ZTickSvg from "@/parts/Front/components/shared/ZTickSvg";

export default {
  name: "Tick",
  components: {
    ZTickEmptySvg,
    ZTickSvg
  },
  props: {
    value : {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>
